import React from "react";
import Header from "./Header";
import { connect } from "react-redux";
import { resetClientDetails } from "../../actions/user";
import history from "../../utils/history";
import { toggleLocale } from "../../actions/locale";

class HeaderContainer extends React.Component {
    handleClientListClick = () => {
        this.props.resetClientDetails();

        history.push("/select-client");
    };

    render() {
        const { clientName, toggleLocale, lang, clientUserName, fairPoints } = this.props;

        return (
            <Header
                clientName={clientName}
                handleClientListClick={this.handleClientListClick}
                toggleLocale={toggleLocale}
                lang={lang}
                clientUserName={clientUserName}
                fairPoints={fairPoints}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        clientName: state.clientDetails.data.name || "",
        clientUserName: state.clientDetails.data.username || "",
        lang: state.locale.lang,
        fairPoints: state.fairPoints
    };
};

export default connect(
    mapStateToProps,
    { resetClientDetails, toggleLocale }
)(HeaderContainer);
