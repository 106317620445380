import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import Filters from "./Filters";
import history from "../../utils/history";
import { fetchProducts } from "../../routines/product";
import * as qs from "qs";
import { fetchActiveCart } from "../../routines/cart";

class FiltersContainer extends React.Component {
    state = {
        myList: history.location.search.split('&')[0].split('=')[0] === '?mylist' ? Number(history.location.search.split('&')[0].split('=')[1]) : 1
    };

    componentDidMount() {
        const { cartItemsCount, fetchActiveCart } = this.props;

        //TODO Test if this is needed

        // if ((!allItemsCount || !clientItemsCount) && !isProductCountLoading) {
        //     fetchProducts(queryParams);
        // }

        if (!cartItemsCount) {
            fetchActiveCart();
        }
    }

    onSelectFilter = type => {
        if (type === "cart") {
            this.setState({ myList: 2 });
            history.push("/cart");
        }

        if (type === "all") {
            this.handleProductTypeSelect(0);
            this.setState({ myList: 0 });
        }

        if (type === "client") {
            this.handleProductTypeSelect(1);
            this.setState({ myList: 1 });
        }
    };

    handleProductTypeSelect = myList => {
        const { username } = this.props;

        const queryParams = qs.parse(history.location.search.slice(1));
        queryParams.mylist = myList;
        queryParams.username = username;

        history.push({
            ...this.props.location,
            pathname: "/",
            search: qs.stringify(queryParams)
        });

        this.props.fetchProducts(queryParams);
    };

    render() {
        const {
            allItemsCount,
            clientItemsCount,
            isProductCountLoading,
            cartItems
        } = this.props;
        const pathname = history.location.pathname;

        return (
            <Filters
                onSelectFilter={this.onSelectFilter}
                allItemsCount={allItemsCount}
                clientItemsCount={clientItemsCount}
                cartItems={cartItems}
                isProductCountLoading={isProductCountLoading}
                myList={this.state.myList}
                pathname={pathname}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        allItemsCount: state.products.data.countAll,
        clientItemsCount: state.products.data.countUser,
        cartItemsCount: state.cart.data ? state.cart.data.length : 0,
        isProductCountLoading:
            state.products.loading && state.products.data.length === 0,
        username: state.clientDetails.data.username
    };
};

export default connect(
    mapStateToProps,
    { fetchProducts, fetchActiveCart }
)(FiltersContainer);

FiltersContainer.propTypes = {
    allItemsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cartItemsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    clientItemsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fetchProducts: PropTypes.func.isRequired,
    isProductCountLoading: PropTypes.bool,
    location: PropTypes.any,
    username: PropTypes.string
};

FiltersContainer.defaultProps = {
    allItemsCount: 0,
    cartItemsCount: 0,
    clientItemsCount: 0,
    isProductCountLoading: true
};
