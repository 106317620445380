import {takeEvery} from "redux-saga/effects";
import {loginUserSaga, logoutUserSaga} from "./sagas/securitySagas";
import {loginUser, logoutUser} from "./routines/security";
import {fetchDeliveryDates} from "./routines/delivery";
import {fetchDeliveryDatesSaga} from "./sagas/deliverySaga";
import {fetchProductsSaga} from "./sagas/productSaga";
import {fetchProducts} from "./routines/product";
import {fetchProductData} from "./routines/product";
import {fetchActiveCart, createCart, deleteCart, addItemCart, completeCart} from "./routines/cart";
import {
    addProductToCartSaga,
    completeCartSaga,
    createCartSaga,
    deleteCartSaga,
    fetchActiveCartSaga
} from "./sagas/cartSaga";
import {fetchClientDetails, setClientUsername, fetchUserDetails} from "./routines/user";
import {fetchClientDetailsSaga, fetchUserDetailsSaga, setClientUsernameSaga} from "./sagas/userSaga";
import {fetchFairCarts, fetchFairBasicInfo, fetchFairPoints} from "./routines/fair";
import {fetchCartsSaga, fetchBasicInfoSaga, fetchPointsSaga} from "./sagas/fairSaga";
import {fetchProductDetailsSaga} from "./sagas/productDetailsSaga";

export default function* rootSaga() {
    yield takeEvery(loginUser.TRIGGER, loginUserSaga);
    yield takeEvery(logoutUser.TRIGGER, logoutUserSaga);

    yield takeEvery(fetchDeliveryDates.TRIGGER, fetchDeliveryDatesSaga);

    yield takeEvery(fetchProducts.TRIGGER, fetchProductsSaga);

    yield takeEvery(fetchProductData.TRIGGER, fetchProductDetailsSaga);

    yield takeEvery(fetchActiveCart.TRIGGER, fetchActiveCartSaga);
    yield takeEvery(createCart.TRIGGER, createCartSaga);
    yield takeEvery(deleteCart.TRIGGER, deleteCartSaga);
    yield takeEvery(addItemCart.TRIGGER, addProductToCartSaga);
    yield takeEvery(completeCart.TRIGGER, completeCartSaga);

    yield takeEvery(fetchClientDetails.TRIGGER, fetchClientDetailsSaga);
    yield takeEvery(fetchUserDetails.TRIGGER, fetchUserDetailsSaga);
    yield takeEvery(setClientUsername.TRIGGER, setClientUsernameSaga);

    yield takeEvery(fetchFairCarts.TRIGGER, fetchCartsSaga);
    yield takeEvery(fetchFairBasicInfo.TRIGGER, fetchBasicInfoSaga);
    yield takeEvery(fetchFairPoints.TRIGGER, fetchPointsSaga);
}