import React from "react";
import ReactTable from "react-table-6";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import withFixedColumns from "react-table-hoc-fixed-columns";
import * as qs from "qs";
import * as R from "ramda";
import history from "../../utils/history";
import debounce from "lodash.debounce";
import FairService from "../../services/api/Fair";
import { connect } from "react-redux";
import { showProductDetails } from "../../actions/productDetails";
import FairSubcomponent from "./components/FairSubcomponent/FairSubcomponent";
const ReactTableFixedColumns = withFixedColumns(ReactTable);

class Table extends React.Component {
    constructor(props) {
        super(props);

        const pageSize = this.props.pageSize ? this.props.pageSize : 50;

        this.state = {
            wrapperRef: undefined,
            headerWidth: 0,
            fixedHeader: false,
            itemsCount: 0,
            originalPageSize: pageSize,
            pageSize: pageSize,
            pages: null,
            fairCartProducts: [],
            fairProducts: []
        };
    }

    componentDidMount() {
        if (this.props.withStickyHeader) {
            document.addEventListener("scroll", this.handleScroll);
            FairService.getFairProductsList({
                username: this.props.username
            }).then(response =>
                this.setState({ fairCartProducts: response.data })
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.itemsCount !== this.props.itemsCount &&
            typeof this.props.itemsCount !== "undefined"
        ) {
            const pageSize =
                this.state.originalPageSize < this.props.itemsCount
                    ? this.state.originalPageSize
                    : this.props.itemsCount;
            this.setState({
                pageSize: pageSize
            });
        }

        if (this.props !== prevProps) {
            this.props.data.length > 0 &&
                this.props.data.forEach(
                    item =>
                        this.state.fairCartProducts[item.product.id] &&
                        item.fair_product_promotions.forEach(it =>
                            this.state.fairCartProducts[item.product.id][
                                it.week_date
                            ]
                                ? (it.value = this.state.fairCartProducts[
                                      item.product.id
                                  ][it.week_date])
                                : null
                        )
                );
            this.setState({ fairProducts: this.props.data });
        }
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        if (!props.itemsCount && props.data) {
            //FIX FOR OTHER TABLES WHERE IS NOT API CALL YET
            return {
                pages: Math.ceil(props.data.length / state.pageSize)
            };
        }

        if (state.itemsCount !== props.itemsCount) {
            newState.pages = Math.ceil(props.itemsCount / state.pageSize);
            newState.pageSize =
                state.originalPageSize < props.itemsCount
                    ? state.originalPageSize
                    : props.itemsCount;
        }

        return newState;
    }

    setWrapperRef = node => {
        this.setState({
            wrapperRef: node
        });
    };

    setFairProducts = newState => {
        this.setState({
            fairProducts: newState
        });
    };

    setFixedHeader = status => {
        if (this.state.fixedHeader && status) {
            return;
        }

        this.setState({
            fixedHeader: status,
            headerWidth: this.state.wrapperRef.getBoundingClientRect().width
        });
    };

    handleScroll = () => {
        const node = this.state.wrapperRef;

        if (node?.getBoundingClientRect()?.top < 0) {
            this.state.fixedHeader === false && this.setFixedHeader(true);
        } else {
            this.state.fixedHeader && this.setFixedHeader(false);
        }
    };

    scrollToTableTop = () => {
        const node = this.state.wrapperRef;

        if (node) {
            node.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    onPageSizeChange = pageSize => {
        this.setState(
            {
                pageSize,
                originalPageSize: pageSize
            },
            () => {
                if (!this.props.manual) {
                    return;
                }
                const queryParams = qs.parse(history.location.search.slice(1));
                queryParams.limit = pageSize;
                this.props.onFetchData(queryParams);
            }
        );
    };

    onFetchData = (state) => {
        if (!this.props.manual) {
            //Do not add params to url when manual is not set. (means we still use fake data)
            return;
        }

        const queryParams = qs.parse(history.location.search.slice(1)); //Will be used for delete old filters & search
        const queryParamsOriginal = { ...queryParams }; //Will be used in comparasion

        const params = {
            page: (++state.page).toString(),
            limit: this.state.originalPageSize
        };

        if (state.sorted.length) {
            params.sort = `${state.sorted[0].id},${
                state.sorted[0].desc ? "DESC" : "ASC"
            }`;
        }

        const newParams = { ...queryParams, ...params };
        const { limit, ...newParamsWithoutLimit } = newParams;

        if (
            !R.equals(newParamsWithoutLimit, queryParamsOriginal) ||
            R.isEmpty(this.props.data)
        ) {
            this.props.onFetchData(newParams); //Fire original onFetchData from Container Component
        }

        history.push({
            ...this.props.location,
            search: qs.stringify(newParamsWithoutLimit)
        });

        if (newParams.s_all !== undefined) {
            this.scrollToTableTop();
        }
    };

    onFetchDataWithDebounce = debounce(this.onFetchData, 500);

    fetchStrategy = state => {
        if (this.props.filterable) {
            return this.onFetchDataWithDebounce(state);
        } else {
            return this.onFetchData(state);
        }
    };

    setFairCartProducts = newState => {
        this.setState({ fairCartProducts: newState });
    };

    render() {
        const {
            data,
            columns,
            intl,
            showPaginationBottom,
            showPageSizeOptions,
            addHoverClassOnColumn,
            loading,
            highlight,
            showProductDetails,
            addProduct,
            loadingState,
            ...rest
        } = this.props;
        const { fairCartProducts } = this.state;

        return (
            <div
                ref={this.setWrapperRef}
                className={`table ${loading ? "table--loading " : ""}`}
            >
                <ReactTableFixedColumns
                    {...rest}
                    data={this.state.fairProducts}
                    columns={columns}
                    pageSize={this.props.pageSize || this.state.pageSize}
                    pages={this.state.pages}
                    className={`table__rt ${
                        highlight ? "-highlight" : ""
                    } -striped ${
                        this.state.fixedHeader ? "table__rt--fixed-header" : ""
                    }`}
                    filterable={false}
                    resizable={false}
                    previousText={intl.formatMessage({
                        id: "table.footer.prev_text"
                    })}
                    nextText={intl.formatMessage({
                        id: "table.footer.next_text"
                    })}
                    pageText={intl.formatMessage({
                        id: "table.footer.page_text"
                    })}
                    ofText={intl.formatMessage({ id: "table.footer.of_text" })}
                    noDataText={intl.formatMessage({
                        id: "table.no_data_text"
                    })}
                    rowsText={intl.locale === "nl" ? "RIJEN" : "ROWS"}
                    showPaginationBottom={showPaginationBottom}
                    showPageSizeOptions={showPageSizeOptions}
                    collapseOnDataChange={false}
                    onFetchData={(state) => this.fetchStrategy(state)}
                    defaultExpanded={data.map(x => true)}
                    expanded={data.map(x => true)}
                    SubComponent={row => {
                        return (
                            <FairSubcomponent
                                row={row}
                                fairProducts={this.state.fairProducts}
                                fairCartProducts={fairCartProducts}
                                setFairProducts={this.setFairProducts}
                                setFairCartProducts={this.setFairCartProducts}
                                addProduct={addProduct}
                                loadingState={loadingState}
                            />
                        );
                    }}
                    getTheadProps={() => {
                        if (this.state.headerWidth > 0) {
                            return {
                                style: {
                                    width: this.state.headerWidth
                                }
                            };
                        }

                        return {
                            style: {}
                        };
                    }}
                    getTdProps={(state, rowInfo, column) => {
                        return {
                            onClick: (e, handleOriginal) => {
                                const clicableColumns = [
                                    "article_nr",
                                    "productName",
                                    "name",
                                    "supplier"
                                ];
                                if (clicableColumns.includes(column.id)) {
                                    /*
                                        sometimes rowinfo.original has product id as rowInfo.original.id but sometime as
                                        rowInfo.original.product.id, so below is temporary solution
                                    */
                                    showProductDetails(
                                        rowInfo.original.product
                                            ? rowInfo.original.product.id
                                            : rowInfo.original.id
                                    );
                                }

                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            },

                            onMouseOver: () => {
                                if (addHoverClassOnColumn) {
                                    addHoverClassOnColumn(column.id);
                                }
                            },

                            onMouseLeave: () => {
                                if (addHoverClassOnColumn) {
                                    addHoverClassOnColumn(null);
                                }
                            }
                        };
                    }}
                    getThProps={() => {
                        return {
                            onMouseLeave: () => {
                                if (addHoverClassOnColumn) {
                                    addHoverClassOnColumn(null);
                                }
                            }
                        };
                    }}
                    onPageChange={this.scrollToTableTop}
                    onPageSizeChange={this.onPageSizeChange}
                />
            </div>
        );
    }
}

Table.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array.isRequired,
    withStickyHeader: PropTypes.bool
};

Table.defaultProps = {
    withStickyHeader: true,
    highlight: true,
    showPaginationBottom: true,
    showPageSizeOptions: true,
    data: []
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = { showProductDetails };

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Table)
);
