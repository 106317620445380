import React from "react";
import Products from "./Products";
import { connect } from "react-redux";
import getColumns from "../../Table/getColumns";
import { fetchProducts } from "../../../routines/product";
import { fetchActiveCart } from "../../../routines/cart";
import * as qs from "qs";
import history from "../../../utils/history";
import FairService from "../../../services/api/Fair";

class ProductsContainer extends React.Component {
    state = {
        fairCartProducts: [],
        loadingState: {},
    };
    
    componentDidMount() {
        FairService.getFairProductsList({ username: this.props.username }).then(
            response => this.setState({ fairCartProducts: response.data })
        );
    }

    fetchData = async params => {
        history.push({
            ...this.props.location,
            search: qs.stringify(params)
        });

        await FairService.getFairProductsList({}).then(
            response => this.setState({ fairCartProducts: response.data })
        );

        this.props.fetchProducts(params);
    };

    addProduct = async (week, productId, amount) => {
        const loadStateKey = `${productId}-${week}`;

        this.setState((prevState) => ({ loadingState: {...prevState.loadingState, [loadStateKey]: true} }))

        await FairService.addProduct(
            week,
            productId,
            amount === "" ? 0 : amount,
            { username: this.props.username }
        );

        const queryParams = qs.parse(history.location.search.slice(1));
        
        this.fetchData(queryParams);

        this.props.fetchActiveCart();

        await FairService.getFairProductsList({ username: this.props.username }).then(
            response => this.setState((prevState) => ({ fairCartProducts: response.data, loadingState: {...prevState.loadingState, [loadStateKey]: false} }))
        );
    };

    render() {
        const { columns, data, itemsCount, isLoading, username } = this.props;
        return (
            <Products
                columns={columns}
                data={data}
                isLoading={isLoading}
                itemsCount={itemsCount}
                handleFetchData={this.fetchData}
                username={username}
                addProduct={this.addProduct}
                fairCartProducts={this.state.fairCartProducts}
                loadingState={this.state.loadingState}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        username: state.clientDetails.data.username,
        data: state.products.data.data,
        itemsCount: state.products.data.total,
        isLoading: state.products.loading,
        columns: getColumns()
    };
};

export default connect(
    mapStateToProps,
    { fetchProducts, fetchActiveCart }
)(ProductsContainer);
