import React from "react";
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl";
import {Button, Container} from "mdbreact";
import ClientForm from "./ClientForm";
import QRCodeForm from "./QRCodeForm";
import qrCodeIcon from "../../../assets/images/qr-code.png";

class SelectClient extends React.Component {
    state = {
        showQRCode: false,
    };

    showQRCode = () => {
        this.setState({showQRCode: true})
    };

    hideQRCode = () => {
        this.setState({showQRCode: false})
    };

    render() {
        const {place, date, supplierName, clientNumber, isLoading, setClientNumber, sendClientNumber, logout} = this.props;

        return (
            <Container fluid className="select-client">
                <p className="select-client__logout" onClick={logout}><FormattedMessage id="security.logout"/></p>
                <h1 className="select-client__title">- <FormattedMessage id="login.title"/> -</h1>
                <p data-testid="fair-info">
                    {place && place.toUpperCase()} - {date && date.toUpperCase()} - {supplierName && <strong>{supplierName.toUpperCase()}</strong>}
                </p>

                <div className="select-client__box">
                    {this.state.showQRCode
                        ? <QRCodeForm
                            hideQRCode={this.hideQRCode}
                            setClientNumber={setClientNumber}
                            sendClientNumber={sendClientNumber}
                        />
                        : <>
                            <ClientForm
                                clientNumber={clientNumber}
                                isLoading={isLoading}
                                setClientNumber={setClientNumber}
                                sendClientNumber={sendClientNumber}
                            />
                            <div className="select-client__box__separator">
                                <div className="select-client__box__separator__txt"><FormattedMessage id="select_client.or"/></div>
                            </div>
                            <div className="select-client__box__qrcode">
                                <Button
                                    className="btn btn-brand btn-flat"
                                    onClick={this.showQRCode}
                                >
                                    <FormattedMessage id="select_client.qr_code.btn" />
                                    <img
                                        style={{ width: "28px", marginLeft: "10px" }}
                                        src={qrCodeIcon}
                                        alt={""}
                                    />
                                </Button>
                            </div>
                        </>
                    }
                </div>
            </Container>
        );
    }
}

SelectClient.propTypes = {
  place: PropTypes.string,
  date: PropTypes.string,
  clientNumber: PropTypes.string,
  setClientNumber: PropTypes.func,
  sendClientNumber: PropTypes.func,
  isLoading: PropTypes.any,
  logout: PropTypes.func,
  supplierName: PropTypes.string
};

export default SelectClient;
