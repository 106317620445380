import request from './request';
import moment from "moment";

const Product = {
    get: (params) => {
        return request({
            url: `/api/fair/product`,
            method: 'GET',
            params: params
        });
    },
    show: (params) => {
        const {id, username} = params;

        return request({
            url: `/api/product/${id}`,
            method: 'GET',
            params: {
                username: username
            }
        });
    },
    getSpecificationPdf: (id) => {
        return request({
            url: `/api/product/allergensheet/${id}`,
            method: 'GET',
            responseType: 'arraybuffer'
        });
    },
};

export default Product;