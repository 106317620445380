import React from "react";
import { FormattedMessage } from "react-intl";
import blackStar from "./../../assets/images/black-star-icon.png";

const getColumns = hideByDefault => {
    const columns = [
        {
            expander: true,
            show: false
        },
        {
            id: "article_nr",
            Header: <FormattedMessage id="table.header.product_no" />,
            accessor: "article_nr",
            width: 85,
            headerClassName:
                "table__rt__col table__rt__col--fix-width table__rt__id",
            className: "table__rt__col table__rt__col--fix-width table__rt__id",
            show: true,
            Cell: row =>
                row.original
                    ? row.original.product.article_nr
                          .toString()
                          .padStart(7, "0")
                    : ""
        },
        {
            id: "name",
            Header: <FormattedMessage id="table.header.product_name" />,
            accessor: "name",
            style: { overflow: "visible", whiteSpace: "normal" },
            headerClassName: "table__rt__col table__rt__product-name",
            className: "table__rt__col table__rt__product-name",
            show: true,
            Cell: row =>
                row.original ? (
                    <span>
                        {row.original.product.name}
                        {row.original.isClientProduct ? (
                            <img
                                style={{ width: "16px", marginLeft: "5px" }}
                                src={blackStar}
                                alt={""}
                            />
                        ) : (
                            ""
                        )}
                        {row.original.new ? (
                            <span className={"chip-new-label"}>NEW</span>
                        ) : (
                            ""
                        )}
                    </span>
                ) : (
                    ""
                )
        },
        {
            id: "supplier",
            Header: <FormattedMessage id="table.header.supplier" />,
            accessor: "supplier",
            className: "table__rt__col table__rt__supplier",
            show: true,
            Cell: row => (row.original ? row.original.product.supplier : "")
        },
        {
            id: "promo",
            Header: <FormattedMessage id="table.header.promo" />,
            preferencesLabel: <FormattedMessage id="table.preferences.promo" />,
            accessor: "promo",
            width: 85,
            className: "table__rt__col table__rt__promo",
            show: true,
            Cell: row => {
                const isBoardGamePromo =
                    row.original &&
                    row.original.fair_product_promotions.find(
                        it => it.promo_type === "B"
                    );
                return row.original &&
                    (row.original.promo || row.original.points) ? (
                    row.original.points ? (
                        <div>
                            {row.original.points}{" "}
                            {isBoardGamePromo ? (
                                <FormattedMessage id="units.pts" />
                            ) : (
                                "EUR"
                            )}
                            /{row.original.per_amount}{" "}
                            <FormattedMessage id="units.st" />
                        </div>
                    ) : (
                        `${row.original.promo}%`
                    )
                ) : (
                    ""
                );
            }
        },
        {
            id: "euro",
            Header: <FormattedMessage id="table.header.total_discount" />,
            accessor: "total_discount",
            width: 95,
            className: "table__rt__col table____rt__total_discount",
            show: true,
            Cell: row => (
                <button
                    disabled={true}
                    style={{
                        backgroundColor: "gray",
                        color: "#111",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "default"
                    }}
                >
                    {row.original ? row.original.total_discount : 0}
                </button>
            )
        }
    ];

    if (hideByDefault) {
        columns.forEach((column, index) => {
            if (hideByDefault.includes(column.id)) {
                columns[index].show = false;
            }
        });
    }

    return columns;
};

export default getColumns;
