import request from './request';
import store from "../../utils/store";
import {fetchFairPoints} from "../../routines/fair";

const Fair = {
    getProducts: (params) => {
        return request({
            url: `/api/fair/product`,
            method: 'GET',
            params: params
        });
    },
    getBasicInfo: () => {
        return request({
            url: `/api/fair/basic-info`,
            method: 'GET',
        });
    },
    getPointsInfo: (params) => {
        return request({
            url: `/api/fair/points-info`,
            method: 'GET',
            params
        });
    },
    getBaskets: () => {
        return request({
            url: `/api/fair-cart/baskets`,
            method: 'GET'
        });
    },
    getFairProductsList: (params) => {
        return request({
            url: `/api/fair-cart/products`,
            method: 'GET',
            params
        });
    },
    addProduct: (week, productId, amount, params) => {
        return request({
            url: `/api/fair-cart/${week}/${productId}/${amount}`,
            method: 'PUT',
            params
        }).then(() => {
            store.dispatch(fetchFairPoints(params))
        });
    },
};

export default Fair;